// src/components/FacturaDetails.js
import React, { useState, useEffect, useRef} from 'react'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import { useAuth } from './AuthContext'
import '../styles/factura.css'

let hostProd=''

if(process.env.REACT_APP_ENV === "DESARROLLO"){
  hostProd = process.env.REACT_APP_DEV_ENV
}
else{
  hostProd = process.env.REACT_APP_PRO_ENV
}

const useOnceEffect = (effect) => {
  const didRun = useRef(false);
  useEffect(() => {
    if (!didRun.current) {
      effect();
      didRun.current = true;
    }
  }, [effect]);
};

const Factura = () => {
    const [dataComprador, setDataComprador] = useState(null);
    const [dataFactura, setDataFactura] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadFile, setLoadFile] = useState(false);
    const [eventFile, setEventFile] = useState(false);

    const { loggedIn, userid } = useAuth();
    const fileInputRef = useRef(null);
    const [mensaje, setMensaje] = useState("");

    const uploadClick = () => {
      fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const token = JSON.parse(localStorage.getItem('user')).token;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userid', userid);

        try {
          const respuestaArchivo = await fetch(`${hostProd}/api/cargarfactura`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData, 
          });
  
          const resArchivo = await respuestaArchivo.json();

          console.log("Respuesta: ",resArchivo);

          if (resArchivo.errorcode === 0) {
            window.alert("Factura cargada exitosamente")
            consultaFactura();
          }
          else {
            window.alert(resArchivo.respuesta)
          }
        } catch (error) {
          console.error('Error al cargar factura:', error);
        }
        setEventFile(true);
        setLoadFile(true);
      }
      else{
        setEventFile(true);
        setLoadFile(false);
        return
      }
    };

    useOnceEffect(() => {   
      consultaFactura();
    });

    const consultaFactura = async () => {
      const user = JSON.parse(localStorage.getItem('user'))

      if (!userid || !user.token || (eventFile && !loadFile)) {
        return
      }

      try{
        setIsLoading(true);
        const respuestaComprdor = await fetch(`${hostProd}/api/consultacomprador/${userid}`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${user.token}` },
        });

        const resComprador = await respuestaComprdor.json();

        if(resComprador.errorcode !== 0){
          setDataComprador('')
          setMensaje(resComprador.respuesta);
        }
        else{
          setDataComprador(resComprador)
          const respuestaFactura = await fetch(`${hostProd}/api/consultafactura/${userid}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${user.token}` },
          });
          const resFactura = await respuestaFactura.json();
          if(resFactura.errorcode !== 0){
            setDataFactura('')
            setMensaje(resFactura.respuesta);
          }
          else
          setDataFactura(resFactura)
        }
        setIsLoading(false);
      }
      catch (error){
          console.error('Error consultando datos para factura:', error);
          window.alert('Se produjo un error durante consulta factura. Por favor, inténtelo de nuevo.');
      }  
    }

    if (!loggedIn) {
      return <h2>No estás autorizado para ver esta página</h2>
    }

    if (!dataComprador && !dataFactura) {
      return <div>"{mensaje}"</div>;
    }

    return (
      <div>
          <div className="header-container">
            <h2>Información sobre sus facturas electrónicas</h2>
            <a href="#" className="upload-link" onClick={uploadClick}>Cargar una factura XML</a>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".xml"
              onChange={handleFileChange}
            />
          </div>  
      {isLoading ? (
        <p>Cargando información...</p> ) : 
        <CompradorInfo info={dataComprador.respuesta.compradorInfo} />
      }  
      {isLoading ? (
        <p>Cargando información...</p> ) :  
        dataFactura?.respuesta?.facturaInfo ? (
          <>  
            <ValorFacturaInfo info={dataFactura.respuesta} />
            {dataFactura.respuesta.facturaInfo.length > 0 ? (
            dataFactura.respuesta.facturaInfo.map((factura, index) => (
                <FacturaInfo key={index} info={factura} />
              ))
            ) : (
              <p>Aún no tiene facturas registradas.</p>  
            )}
          </>
        ) : (
            <h2>Aún no tiene facturas registradas..</h2>
          )
      }  
      </div>
    );
}
  
const CompradorInfo = ({ info }) => (
  <div className="compradorInfo">
    <h2>Datos personales</h2>
    <p><strong>Nombre:</strong> {info.nombre_comprador}</p>
    <p><strong>Nro. de Identificacion:</strong> {info.comprador_id}</p>
    <p><strong>Teléfono:</strong> {info.telefono_comprador}</p>
    <p><strong>Email:</strong> {info.email_comprador}</p>
  </div>
);

const ValorFacturaInfo = ({info}) => (
  <div className="compradorInfo">
    <h2>Valor total de sus facturas</h2>
    <p><strong>Valor total =</strong> {formatearValor(info.totalFactura)}</p>
  </div>
);

function formatearValor (valorSinFormato) {
  const valorConFormato = parseFloat(valorSinFormato).toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP', 
    minimumFractionDigits: 2,
  });
  return valorConFormato;
}  

function formatearFecha (fechaSinFormato) {
  const date = new Date(fechaSinFormato); 
  const fechaConFormato = format(parseISO(fechaSinFormato), "d 'de' MMMM 'de' yyyy", { locale: es });
  return fechaConFormato;
}  

const FacturaInfo = ({ info }) => (
  <div className="facturaInfo">
    <h2><strong></strong> {info.nombre_vendedor}</h2>
    <p><strong>Id. factura:</strong> {info.factura_id}</p>
    <p><strong>Valor factura:</strong> {formatearValor(info.valor_factura)}</p>
    <p><strong>Fecha de compra:</strong> {formatearFecha(info.fecha_factura)}</p>
    <p><strong>Ciudad:</strong> {info.ciudad_vendedor}</p>
    <details>
      <summary>Lista de Productos</summary>
        <ul>
          {info.productosInfo.map((producto, index) => (
            <li key={index}>
              <p><strong>Producto:</strong> {producto.producto}</p>
              <p><strong>Precio:</strong> {formatearValor(producto.precio)}</p>
              <p><strong>Impuesto:</strong> {formatearValor(producto.impuesto)}</p>
            </li>
          ))}
        </ul>
    </details>
  </div>
);

export default Factura;
