import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [hommedIn, setHommedIn] = useState('');
  const [userid, setUserId] = useState('');

  const login = (userid) => {
    setLoggedIn(true);
    setUserId(userid);
  };

  const logout = () => {
    setLoggedIn(false);
    setUserId(0);
  };

  const homein = (username) => {
    setHommedIn(true);
    setUserId(userid);
  };

  return (
    <AuthContext.Provider value={{ loggedIn, hommedIn, userid, login, logout, homein }}>
      {children}
    </AuthContext.Provider>
  );
};
