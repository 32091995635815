import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import CryptoJS from 'crypto-js'
import '../styles/login.css'

const Registro = () => {
  const [userid, setUserId] = useState('')
  const [username, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [password, setPassword] = useState('')

  const [userNameError, setUserNameError] = useState('')
  const [userIdError, setUserIdError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [telefonoError, setTelefonoError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()
  const { homein, hommedIn } = useAuth();

  let hostProd=''

  if(process.env.REACT_APP_ENV === "DESARROLLO"){
    hostProd = process.env.REACT_APP_DEV_ENV
  }
  else{
    hostProd = process.env.REACT_APP_PRO_ENV
  }

  if (!hommedIn) {
    return <h2>No estás autorizado para ver esta página</h2>
  }

  const onButtonClick = () => {
    setUserNameError('')
    setUserIdError('')
    setEmailError('')
    setTelefonoError('')
    setPasswordError('')

    if (!userid) {
        setUserIdError('Por favor ingrese un número de identificación')
        return
    }

    if (!username) {
        setUserNameError('Por favor ingrese su nombre')
        return
    }

    if (!email) {
        setEmailError('Por favor ingrese un correo electrónico')
        return
    }

    if (!telefono) {
        setTelefonoError('Por favor ingrese un teléfono')
        return
    }

    if(!password){
        setPasswordError('Por favor ingrese un password')
        return
    }

    signUp()
  }
  
  const signUp = async () => { 
    try{ 

        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedPassword = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(secretKey), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString();

        const response = await fetch(`${hostProd}/api/registro`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({ userid, username, email, telefono, password: encryptedPassword }),
        })

        const res = await response.json();
        
        if (res.errorcode === 0) {
            window.alert('Registro exitoso. Inicie sesión con los datos proporcionados!')
            homein('guest')
            navigate('/login')
        }
        else {
            window.alert(res.respuesta)
        }
    }
    catch (error) {
        window.alert('Se produjo un error durante el registro. Por favor, inténtelo de nuevo.');
    }    
  }

  return (
    <div className="loginContainer">
      <div className="loginTitle">
        <h1>Información para registro</h1>
      </div>

      <div className="inputGroup">
        <label htmlFor="userid">Nro. de identificación</label>
        <input
          id="userid"
          value={userid}
          placeholder="Digite su numero de identificación"
          onChange={(ev) => setUserId(ev.target.value)}
          className="inputField"
          required
        />
        <label className="errorLabel">{userIdError}</label>
      </div>

      <div className="inputGroup">
        <label htmlFor="username">Nombre</label>
        <input
          id="username"
          value={username}
          placeholder="Digite sus nombres y apellidos"
          onChange={(ev) => setUserName(ev.target.value)}
          className="inputField"
          required
        />
        <label className="errorLabel">{userNameError}</label>
      </div>

      <div className="inputGroup">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          placeholder="Digite su correo electrónico"
          onChange={(ev) => setEmail(ev.target.value)}
          className="inputField"
          required
        />
        <label className="errorLabel">{emailError}</label>
      </div>

      <div className="inputGroup">
        <label htmlFor="telefono">Teléfono</label>
        <input
          id="telefono"
          value={telefono}
          placeholder="Digite su telefono de contacto"
          onChange={(ev) => setTelefono(ev.target.value)}
          className="inputField"
          required
        />
        <label className="errorLabel">{telefonoError}</label>
      </div>

      <div className="inputGroup">
        <label htmlFor="password">Contraseña</label>
        <input
          id="password"
          type="password"
          value={password}
          placeholder="Asigne una contraseña"
          onChange={(ev) => setPassword(ev.target.value)}
          className="inputField"
          required
        />
        <label className="errorLabel">{passwordError}</label>
      </div>

      <div className="inputGroup">
        <input className="loginButton" type="button" onClick={onButtonClick} value="Confirmar" />
      </div>
    </div>
  );
}

export default Registro