import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../styles/home.css';

const Home = () => {
  const navigate = useNavigate();
  const { homein } = useAuth();

  const onButtonClick = () => {
    homein('guest');
    navigate('/login');
  };
  
  return (
    <div className="mainContainer">
      <header className="titleContainer">
        <h1>Innvo</h1>
        <p>Gestione la información de sus facturas electrónicas</p>
      </header>
      <div className="buttonContainer">
        <button className="startButton" onClick={onButtonClick}>
          Ingresar
        </button>
      </div>
    </div>
  );
}

export default Home