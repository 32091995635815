import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import CryptoJS from 'crypto-js'
import axios from 'axios';
import '../styles/login.css'

let hostProd=''

if(process.env.REACT_APP_ENV === "DESARROLLO"){
  hostProd = process.env.REACT_APP_DEV_ENV
}
else{
  hostProd = process.env.REACT_APP_PRO_ENV
}

const Login = () => {
  const [userid, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [userNameError, setUserIdError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const navigate = useNavigate()
  const { homein, login, hommedIn } = useAuth();
  
  if (!hommedIn) {
    return <h2>No estás autorizado para ver esta página</h2>
  }

  const onButtonClickLogin = () => {
    setUserIdError('')
    setPasswordError('')

    if (!userid) {
    setUserIdError('Por favor ingrese su numero de identificación')
    return
    }

    if (!password) {
      setPasswordError('Por favor ingrese un password')
      return
    }

    if (password.length < 4) {
      setPasswordError('El password debe tener al menos 4 caracteres')
      return
    }

    logIn()
  }

  const onButtonClickRegistro = () => {
    homein('guest')
    navigate('/Registro')  
  }  

  const logIn = async () => {
    try {

      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const encryptedPassword = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(secretKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();

      const response = await axios.post(`${hostProd}/api/login`, 
      {
        userid: userid,
        password: encryptedPassword
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const { errorcode, respuesta, token } = response.data;
      if (errorcode !== 0) {
        window.alert(respuesta);
      }
      else {
        localStorage.setItem('user', JSON.stringify({ token: token, userid: userid }))
        login(userid);
        navigate('/factura')
      }
    }
    catch (error) {
      window.alert('Se produjo un error durante el login. Por favor, inténtelo de nuevo.');
    } 
  }

  return (
    <div className="loginContainer"> {/* Use a more descriptive name */}
      <div className="loginTitle">
        <h1>Innvo</h1> {/* Use heading for clarity */}
      </div>

      <div className="inputGroup">
        <label htmlFor="username">Nro. de identificación:</label>
        <input
          id="username"
          value={userid}
          placeholder="Digite su número de identificación"
          onChange={(ev) => setUserId(ev.target.value)}
          className="inputField"
          required
        />
        {userNameError && <label className="errorLabel">{userNameError}</label>}
      </div>

      <div className="inputGroup">
        <label htmlFor="password">Contraseña:</label>
        <input
          id="password"
          type="password"
          value={password}
          placeholder="Digite su contraseña"
          onChange={(ev) => setPassword(ev.target.value)}
          className="inputField"
          required
        />
        {passwordError && <label className="errorLabel">{passwordError}</label>}
      </div>

      <button className="loginButton" type="button" onClick={onButtonClickLogin}>
        Ingresar
      </button>

      <div className="registerPrompt">
        <p>No está registrado ?
          <a href="#" onClick={onButtonClickRegistro}> Registrarse </a>
        </p>  
      </div>
    </div>
  );
}

export default Login